// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
// require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
require("trix")
require("@rails/actiontext")


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

document.addEventListener("DOMContentLoaded", function() {
  var galleryImages = document.getElementsByClassName('js-gallery-image');

  Array.prototype.forEach.call(galleryImages, function(galleryImage) {
    galleryImage.addEventListener('click', function(event) {
      var currentActiveImage = document.getElementsByClassName('products__gallery-image--active')[0];
      currentActiveImage.classList.remove('products__gallery-image--active');

      event.target.classList.add('products__gallery-image--active');
      document.getElementById('js-main-image').src = event.target.src;
    });
  });
});
